// extracted by mini-css-extract-plugin
export var column = "UserDashboardEdit__column__hz6k3";
export var container = "UserDashboardEdit__container__A13tV";
export var flex = "UserDashboardEdit__flex__yoIra";
export var flexColumn = "UserDashboardEdit__flexColumn__qR9Jh";
export var gap1 = "UserDashboardEdit__gap1__F7z9r";
export var gap2 = "UserDashboardEdit__gap2___AMAZ";
export var gap3 = "UserDashboardEdit__gap3__FOBqn";
export var gap4 = "UserDashboardEdit__gap4__D490Q";
export var gap5 = "UserDashboardEdit__gap5__f4O3_";
export var pageContainer = "UserDashboardEdit__pageContainer__MVgCt";
export var pageTitle = "UserDashboardEdit__pageTitle__rVY8j";
export var row = "UserDashboardEdit__row__RGMFg";