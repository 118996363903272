// extracted by mini-css-extract-plugin
export var column = "UserDashboardEditForm__column__BpC6l";
export var defaultProfilePic = "UserDashboardEditForm__defaultProfilePic__dvCxj";
export var fieldContainer = "UserDashboardEditForm__fieldContainer__rahBf";
export var flex = "UserDashboardEditForm__flex__DL01L";
export var flexColumn = "UserDashboardEditForm__flexColumn__wfsPx";
export var formRow = "UserDashboardEditForm__formRow__rL381";
export var gap1 = "UserDashboardEditForm__gap1___mxqm";
export var gap2 = "UserDashboardEditForm__gap2__OQTJo";
export var gap3 = "UserDashboardEditForm__gap3__y5Jwo";
export var gap4 = "UserDashboardEditForm__gap4__OuBww";
export var gap5 = "UserDashboardEditForm__gap5__sp4m8";
export var profilePicContainer = "UserDashboardEditForm__profilePicContainer__z7EvJ";
export var row = "UserDashboardEditForm__row__ye92p";
export var twoColumn = "UserDashboardEditForm__twoColumn__Tkl2t";
export var uploadedImage = "UserDashboardEditForm__uploadedImage__AmNEi";
export var uploader = "UserDashboardEditForm__uploader__WWhfM";
export var uploaderInner = "UserDashboardEditForm__uploaderInner__bvxBI";
export var userForm = "UserDashboardEditForm__userForm__o8V6k";